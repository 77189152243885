<template>
  <div class="modal-heading small-heading">
    <div class="left-side">
      <h3>Popularity trend for <span class="blue">{{ currentKeywordItem }}</span></h3>
      <div class="country-select sub-select">
        <custom-select-search @add-app-search-handler="popularityCountryChanged"
                              @country-selected="popularityCountrySelectHandler"
                              no-active-border
                              :classes="['country-select-search no-shadow-results-block']"
                              :initial-data="{name: popularCountryCode?.name || currentCountryName, code: popularCountryCode?.code || currentCountryCode}"
                              :search-results="searchSapCountryResults">
          <template v-slot:header>Select country</template>
        </custom-select-search>
      </div>
    </div>
    <div class="right-side">
      <custom-dropdown :min-width="'112px'"
                       :data-updated="currentCountryCode">
        <template v-slot:header>
          <span class="capitalized">{{ popularSelectedPeriod }}</span>
        </template>
        <template v-slot:content>
          <div class="select-options">
            <div class="option"
                 v-if="popularSelectedPeriod === 'month'"
                 @click="popularityPeriodChanged('week')">Weeks
            </div>
            <div class="option"
                 v-if="popularSelectedPeriod === 'week'"
                 @click="popularityPeriodChanged('month')">Months
            </div>
          </div>
        </template>
      </custom-dropdown>
    </div>
  </div>

  <div class="chart-block">
    <preloader-table-small v-if="showPopularChartLoader"
                           :loader-size="'40px'"></preloader-table-small>

    <template v-else>

      <vue-highcharts
          v-if="popularityChartResultsLength > 0"
          type="chart"
          :options="getPopularData()"
          :redrawOnUpdate="true"
          :oneToOneUpdate="false"
          :animateOnUpdate="true"
          @updated="onUpdated"/>

      <div v-else>No Data</div>
    </template>
  </div>
</template>

<script>
import {formatDate} from "@/utils/utils";
import {keywordsPopularChartOptions} from "@/configs/keywords-popular-charts/keywords-popular-charts";
import {httpRequest} from "@/api";
import CustomDropdown from "@/components/UI/CustomDropdown/index";
import CustomSelectWithSearch from "@/components/Forms/CustomSelectWithSearch/index.vue";
import {mapGetters} from "vuex";


export default {
  name: "SapChartModal",
  components: {
    'custom-dropdown': CustomDropdown,
    'custom-select-search': CustomSelectWithSearch,
  },
  props: {
    currentCountryName: {
      type: String,
    },
    currentCountryCode: {
      type: String,
    },
    currentKeywordItem: {}
  },
  data() {
    return {
      showTableChartModal: false,
      showPopularChartLoader: false,
      popularSelectedPeriod: 'week',
      popularCountryCode: null,
      popularCountryName: null,
      searchSapCountryResults: {},
    }
  },
  async mounted() {
    this.popularCountryCode = this.currentCountryCode;
    this.popularCountryName = this.currentCountryName;
    await this.fetchPopularityChartResults();
  },
  methods: {
    onUpdated() {
    },
    getPopularData() {
      let popularityArray = [];
      for (const key in this.popularityChartResults) {
        popularityArray.push({
          date: formatDate(key, 'month-day-year'),
          rankData: this.popularityChartResults[key]
        });
      }

      let dates = [];
      let rank = [];
      for (const key in this.popularityChartResults) {
        dates.push(formatDate(key, 'month-day-year'));
        rank.push(this.popularityChartResults[key]);
      }

      const rankingData = popularityArray.sort(function (a, b) {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        return dateA < dateB ? -1 : 1;
      });

      let self = this;

      return {
        ...keywordsPopularChartOptions,
        xAxis: {
          categories: rankingData.map(item => item.date),
          labels: {
            // step: steps,
            // step: 1,
            // align: 'left',
            // useHTML: true,
            rotation: -45,
            style: {
              color: '#a2aabe',
              fontSize: '14px',
              textOverflow: 'none',
              whiteSpace: 'nowrap',
            },
            formatter: function () {
              if (rankingData?.length === 12) {
                // if (!this.isFirst && !this.isLast) {
                return this.value;
                // }
              } else if (rankingData?.length > 4 && rankingData?.length < 12) {
                return this.value;
              } else {
                return this.value;
              }
            },
          }
        },
        yAxis: {
          title: false,
          labels: {
            style: {
              color: '#a2aabe',
              fontSize: 14
            },
            formatter: function () {
              return this.value;
            },
          },
          gridLineDashStyle: 'longdash',
        },
        series: [{
          data: rankingData.map(item => item.rankData.SAP),
          lineWidth: 3,
          pointHitRadius: 10,
          marker: {
            states: {
              hover: {
                fillColor: 'white',
                radius: 3,
                lineWidth: 1,
                lineColor: '#7688ff',
              }
            }
          },
          // enableMouseTracking: false
        }],
        options: {
          scales: {
            xAxes: [{
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              stacked: true,
              gridLines: {
                borderDash: [8, 4],
                color: "#f0f3fd",
                zeroLineColor: 'transparent',
                drawBorder: false,
                drawOnChartArea: true,
              },
              type: 'linear',
            }]
          },
        },
        tooltip: {
          enabled: true,
          width: 100,
          distance: 20,
          padding: 8,
          outside: false,
          useHTML: true,
          backgroundColor: '#fff',
          borderColor: '#d7dde7',
          borderRadius: 4,
          shadow: false,
          shape: "square",
          hideDelay: 15,
          formatter: function () {
            let sapDifference = null;
            const category = this.point.category;
            for (const key in self.popularityChartResults) {
              if (category === formatDate(key, 'month-day-year')) {
                sapDifference = self.popularityChartResults[key]?.difference;
                break;
              }
            }

            const differenceColor = sapDifference > 0 ? 'green' : 'red';
            const differenceRenderValue = sapDifference > 0 ? '+' + sapDifference : sapDifference;
            let differenceBlock = '';

            if (sapDifference !== 0 && sapDifference !== null) {
              differenceBlock = `<span class="difference ${differenceColor}">${differenceRenderValue}</span>`;
            }

            return `<div class="tooltip-inner-container">${this.point.category}
                      <br />
                      <span class="rank">Popularity: <strong>${this.point.y}</strong>
                      ${differenceBlock}
                      </span>
                    </div>`
          }
        },
      };
    },
    async fetchPopularityChartResults(period) {
      const store = this.currentApp?.store?.key ?? 'APP_STORE';
      this.showPopularChartLoader = true;
      if (period) {
        this.popularSelectedPeriod = period;
      }

      const url = process.env.VUE_APP_API_URL
          + this.$ApiUrls.keywordsTracking.POPULARITY_DYNAMICS
          + '?keyword=' + encodeURIComponent(this.currentKeywordItem)
          + '&country_code=' + (this.popularCountryCode || this.currentCountryCode)
          + '&period=' + this.popularSelectedPeriod
          + '&store_key=' + store;

      const result = await httpRequest('GET', url);
      this.$store.dispatch('keywordsTracking/SET_POPULARITY_CHART_RESULTS', result?.popularity_dynamics);
      this.showPopularChartLoader = false;
      await this.popularityCountryChanged('');
    },
    async popularityCountryChanged(value) {
      if (value?.length >= 0) {
        const url = process.env.VUE_APP_API_URL
            + this.$ApiUrls.keywordsTracking.POPULARITY_DYNAMICS_COUNTRIES
            + '?country_query=' + value + '&keyword='
            + encodeURIComponent(this.currentKeywordItem);

        const result = await httpRequest('GET', url);

        if (result?.countries) {
          let countriesSapArray = [];
          for (const key in result.countries) {
            countriesSapArray.push({
              code: key,
              name: result.countries[key]?.name,
              sap: result.countries[key]?.SAP,
            });
          }
          this.searchSapCountryResults = {result: countriesSapArray};
        }
      } else {
        this.searchSapCountryResults = {};
      }
    },
    async popularityCountrySelectHandler(value) {
      this.popularCountryCode = value.code;
      this.popularCountryName = value.name;
      await this.fetchPopularityChartResults();
    },
    async popularityPeriodChanged(period) {
      this.popularSelectedPeriod = period;
      await this.fetchPopularityChartResults(period);
    },
    async fetchCountryCatalog() {
      let url = process.env.VUE_APP_API_URL + this.$ApiUrls.user.FILTER_LOCALES;
      const result = await httpRequest('GET', url);
      this.$store.dispatch('SET_COUNTRY_LIST', result);
    },
  },
  computed: {
    ...mapGetters([
      'userData',
      'isMobile',
      'currentApp',
    ]),
    ...mapGetters('keywordsTracking', [
      'popularityChartResults',
    ]),
    popularityChartResultsLength() {
      return Object.values(this.popularityChartResults)?.length;
    },
  },
}
</script>